import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "./userpool";

    const isLoggedIn = () => {
        return UserPool.getCurrentUser() ? true : false;
    }

    const getSession = async () => {
        return await new Promise((resolve, reject) =>{
            const user = UserPool.getCurrentUser();

            if (user){
                user.getSession(async (err,session) =>{
                    if (err){
                        reject()
                        console.error(err)
                    }
                    else {
                        const attributes = await new Promise((resolve, reject) => {
                            user.getUserAttributes((err, attributes)=> {
                                if (err){
                                    reject(err);
                                    console.error(err)
                                }
                                else {
                                    const results = {};
                                    for (let attribute of attributes){
                                        const {Name, Value} = attribute;
                                        var key = Name.replace("custom:", "")
                                        results[key] = Value
                                    }
                                    resolve(results);
                                }
                            });
                        });
                        resolve({user, ...attributes, ...session})     
                    }
                });
            }
            else{
                reject();
                console.error("no user")
            }
        })
    }

    const logout = () => {
        const user = UserPool.getCurrentUser();
        if(user)
            user.signOut();
    }

    const passwordChallenge = async(email, password) => {
        
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        });

        return await new Promise((resolve, reject) => {
            user.completeNewPasswordChallenge(password, {}, {
                onSuccess: (data) => {
                    console.log("onSuccess: ", data);
                    resolve(data)
                },
                onFailure: err => {
                    console.error("onFailure: ", err);
                    reject(err);
                }
            });
        });
    }

    const authenticate = async(email, password) => {
        return await new Promise((resolve, reject) => {

            const user = new CognitoUser({
                Username: email,
                Pool: UserPool
            });

            const authDetails = new AuthenticationDetails({
                Username: email,
                Password: password
            });

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    console.log("onSuccess: ", data);
                    //setUser(user);
                    data['newPasswordRequired'] = false;
                    resolve(data)
                    
                },
                onFailure: (err) => {
                    console.error("onFailure: ", err);
                    reject(err);
                },
                newPasswordRequired: (data) => {
                    console.log("newPasswordRequired: ", data);
                    data['newPasswordRequired'] = true;
                    //setPasswordUser(user);
                    resolve(data)
                }
            });
        });   
    }

    export {getSession, isLoggedIn, logout, passwordChallenge, authenticate}
