import * as React from 'react';
import { Box } from '@mui/material';
import { CircularProgress } from "@mui/material";

function LoadingOverlay() {
    return (       
        <Box sx={{width:"100%", height:"100%", zIndex:"998", display:"flex", alignItems:"center", justifyContent:"center", position:"absolute", background:"rgba(0,0,0,0.45)"}}>
            <CircularProgress sx={{color:"#999"}}/>
        </Box>   
    );
}

export default LoadingOverlay;