import React, {} from "react";
import { Stack, Typography} from "@mui/material";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from '@mui/icons-material/Email';

export default function ContactBar(){

    return (
    
            <Stack spacing={2} direction="row" sx={{background:"#f5f5f5", color:"#181818", justifyContent:"center", alignItems:"center", padding:"0.5em 0", zIndex:999, height:"1.5em"}}>
                <PhoneInTalkIcon sx={{color:"#181818 !important"}}/> 
                <Typography variant="subtitle2" sx={{fontWeight:"bold"}}>
                    <a className="contactLink" href="tel:+18042273023">+1(804) 227-3023</a>
                </Typography>
                <Typography variant = "subtitle2" sx={{padding:"0, 10px", fontWeight:"bold"}}>|</Typography>
                <EmailIcon sx={{color:"#181818 !important"}}/>
                <Typography variant="subtitle2" sx={{fontWeight:"bold"}}>
                    <a className="contactLink" href ="mailto:gsesupport@powertraincontrol.com">Email Support</a>
                </Typography>
            </Stack>


    )
}
