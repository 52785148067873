import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom" 
import { Box, ThemeProvider } from "@mui/material";
import theme from "./themes/default";
import './css/main.css';
import Transmission from "./pages/Transmission";
import EV from './pages/EV';
import VINLookup from "./pages/VINLookup";
import Login from "./pages/Login";
import Admin from "./pages/Admin";

function App() {
  return (
    <ThemeProvider theme = {theme}>
      <Box id="wrapper">
        <Router basename={"/"}>
            <Routes>
              <Route path="/" element={<Transmission />}/>
              <Route path="/ev" element={<EV />}/>
              <Route path="/vinlookup" element={<VINLookup />}/>
              <Route path="/admin/login" element={<Login />}/>
              <Route path="/admin" element={<Admin />}/>
            </Routes>
          </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;