import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Stack, Typography, Grid, Box, Table, TableRow, TableCell, Divider, Button, TableBody } from "@mui/material";
import StyledAppBar from "../components/StyledAppBar";
import json from '../util/downloads.json';
import Footer from "../components/Footer";
import ContactBar from "../components/ContactBar";

const downloads = json.ev;

export default function EV(){

    return (
        <>
            <StyledAppBar />

            <ContactBar />
            
            <Stack sx={{width:{xs:"80%", lg:"75%"}, padding:{xs:"0 10%", lg:"0 12.5%"}, margin:"0 auto", overflowY:"auto", height:"calc(100% - 9em)", background:"#181818", position:"relative"}}>
                    
                <Typography component="div" variant="h5" sx={{fontWeight:"bold",  padding:"30px 0 30px 0", textAlign:"center"}}>
                    <Link to="/" className="whiteLink">CLICK HERE FOR TRANSMISSION SUPPORT AND DOCUMENTATION</Link>
                </Typography>
                
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <Box sx={{textAlign:"left", border:"2px solid #ffffff", borderRadius:"5px", height:{xs:"auto", lg:"225px"}, overflow:"hidden"}}>
                            <Stack sx={{background:"#004191", padding:"0.3em 1.5em", fontWeight:"bold", textAlign:"center"}}>
                                <Typography variant ="h6" sx={{fontWeight:"bold"}}>CUSTOMER SERVICE</Typography>                            
                            </Stack>
                            <Table sx={{margin:"5px"}}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{fontWeight:"bold"}}>Contact:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">Matt Merryman</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{fontWeight:"bold"}}>Phone:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">+1 (804) 227-3023</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{fontWeight:"bold"}}>Email:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">gseorders@powertraincontrol.com</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>                      
                    </Grid>

                    <Grid item xs={12} lg={4} sx={{textAlign:"center"}}>
                        <Box sx={{textAlign:"left", border:"2px solid #fff", borderRadius:"5px", height:{xs:"auto", lg:"225px"}, overflow:"hidden"}}>
                            <Stack sx={{background:"#004191", padding:"5px 20px", textAlign:"center"}}>
                                <Typography variant="h6" sx={{ fontWeight:"bold"}}>TECHNICAL SUPPORT</Typography>
                            </Stack>
                            <Table sx={{margin:"5px"}}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{fontWeight:"bold"}}>Contact:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">Matthew Petrie</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{fontWeight:"bold"}}>Phone:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">+1 (804) 227-3023</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{fontWeight:"bold"}}>Email:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">gsetechs@powertraincontrol.com</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <Divider orientation="horizontal"  flexItem sx={{backgroundColor:"#fff", marginTop:"10px"}}/>

                            <Table sx={{margin:"5px"}}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{fontWeight:"bold"}}>Contact:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">Jim Miller</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{fontWeight:"bold"}}>Phone:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">+1 (804) 227-3023</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{fontWeight:"bold"}}>Email:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">gsetechs@powertraincontrol.com</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>              
                    </Grid>

                    <Grid item xs={12} lg={4} sx={{textAlign:"center"}}>
                        <Box sx={{textAlign:"left", border:"2px solid #fff", borderRadius:"5px", height:{xs:"auto", lg:"225px"}, overflow:"hidden"}}>
                            <Stack sx={{background:"#004191", padding:"5px 20px", fontWeight:"bold", textAlign:"center"}}>
                                <Typography variant="h6" sx={{fontWeight:"bold"}}>WARRANTY ASSISTANCE</Typography>
                            </Stack>
                            <Table sx={{margin:"5px"}}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{fontWeight:"bold"}}>Phone:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">+1 (804) 227-3023</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{fontWeight:"bold"}}>Email:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">gsewarranty@powertraincontrol.com</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>             
                    </Grid>
                </Grid>

                <Stack orientation="vertical" sx={{justifyContent:"center", alignItems:"flex-start", margin:"0 auto", padding:"30px 0", width:"100%", position:"relative"}} spacing={3}>
                    
                    {
                        downloads.length > 0 &&

                            downloads.map((download, index) => 
                               
                                <React.Fragment key={index}>  
                                
                                    {download.files.length > 0 &&
                                        <React.Fragment> 
                                           
                                            <Typography component="div" variant="h5" sx={{ fontWeight:"bold", paddingTop:"1em"}}>
                                                {download.title}
                                            </Typography>

                                            {download.files.map((file, index) => 
                                        
                                                <Button key={"btn-"+index}  
                                                    variant ="contained" 
                                                    href={file.path}
                                                    target="_blank"
                                                    fullWidth 
                                                    sx={{padding:"15px"}}>
                                                        <Typography variant="h6" sx={{fontWeight:"bold"}}>{file.name}</Typography>
                                                </Button> 
                                                
                                            )}
                                        </React.Fragment>
                                    }
                                </React.Fragment>  
                            )                                          
                    }                            
                </Stack>
                <Footer />
            </Stack>
            
        </>
    )
}
