import React, { useState } from "react";
import { Stack, TextField, Box, Alert, Typography, Grid, InputAdornment } from "@mui/material";
import StyledAppBar from "../components/StyledAppBar";
import api from "../services/api";
import Footer from "../components/Footer";
import LoadingOverlay from "../components/LoadingOverlay";
import ContactBar from "../components/ContactBar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';


export default function VINLookup(){

    const [ results, setResults ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ alert, setAlert ] = useState(false);

    const searchForVin = (e) => {
        setAlert(false);
        if(e.keyCode !== 13) return;
        
        const vin = e.target.value;
        const config = { 
            'accept': 'application/json',
        }

        const fetchData = async() => {
            setLoading(true);
            var response = await api.get('/pcssoftware/vin/' + vin, {
                headers: {
                    'X-API-KEY' : process.env.REACT_APP_API_KEY
                }
            }, config);  
            var data = convertBools(response.data);
            console.log(data);
            setResults(data);
            setLoading(false);   
        }
    
        fetchData().catch((error) => {
            console.log(error);
            //display error to customer
            setAlert(true);
            setLoading(false);
        }); 

    }

    
    const convertBools = (data) => {
        for (const [key, value] of Object.entries(data)) {
            if (typeof (value) === "boolean") { 
                data[key] = value ? "Success" : "Failure";
                console.log(value);
            }else{
                data[key] = value;
            }
        }
        
        return data;
    
    }


    return (
        <>
            <StyledAppBar />
            <ContactBar />
            {loading && <LoadingOverlay/>} 
            <Stack sx={{width:"100%",  justifyContent:"center", alignItems:"center", position:"relative", display:"flex", flexDirection:"column", flexGrow:"1", overflowY:"auto"}}>
                
                <Stack sx={{padding:{xs:"20px", md:"10%"}, maxHeight:{xs: "calc(100% - 40px)", md:"calc(100% - 20%)"}, width:{xs:"calc(100% - 40px)", md:"calc(100% - 20%)"}}}>
                    <Typography variant="h3">VIN Lookup</Typography>
        
                    {alert &&
                        <Alert 
                            severity="error" 
                            variant="filled" 
                            sx={{width:"calc(100% - 14px)", padding:"7px", marginTop:"20px", fontWeight:"bold", justifyContent:"flex-start", alignItems:"center", 
                                textTransform:"uppercase", height:"30px", background:"#ffff82", color:"#222", borderRadius:"2px" }}> 
                            
                                We were unable to locate your VIN. Check your VIN and try again.

                        </Alert>
                    }
                                
                    {results.length > 0          
    
                        ?   <Stack sx={{width:"100%", marginTop:"20px", height:"auto" }}>
                                
                                <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center" sx={{borderBottom:"1px solid #333", padding:"15px 0", color:"#999", flexShrink: 0}}> 
                                    <ArrowBackIcon sx={{fontSize:"1em"}} onClick={() => setResults([])}/>
                                    <Typography component="div" variant="h6" sx={{fontWeight:"bold"}}>
                                        Results for <span>'{results[0].VIN}'</span>
                                    </Typography>
                                </Stack> 

                                <Stack sx={{color:"#999", overflowY:"auto", flexGrow:1}}>
                                    {results.map((result, index) => (
                                        <Grid container justifyContent="center" sx={{padding: index === 0 ? "25px 0 50px 0" : "50px 0", borderBottom: (index + 1) !== results.length && "2px solid #888"}} >
                                        
                                                {Object.keys(result).map(key => {
                                                    const k = key.replace(/_/g, " ");
                                                    return(
                                                        <React.Fragment key={key}>
                                                            <Grid item xs={4} sx={{padding:"10px 0", borderBottom:"1px dotted #333"}}>
                                                                <Typography variant="body1" sx={{fontWeight:"bold"}}>
                                                                    {k}
                                                                </Typography>
                                                            </Grid>   
                                                            <Grid item xs={8} sx={{padding:"10px 0", borderBottom:"1px dotted #333"}}>
                                                                <Typography variant="body1">
                                                                    {result[key]}
                                                                </Typography>
                                                            </Grid>
                                                        </React.Fragment>
                                                    )
            
                                                })}
                                            
                                        </Grid>
                                    ))}
                                </Stack>  
                                
                            </Stack>  
                            
                        :   <TextField 
                                id="search"
                                label=""
                                placeholder="Enter your VIN and press enter."
                                InputProps={{
                                    style:{

                                        fontWeight:"bold",
                                        color:"#fff",
                                        background:"#282828",
                                        padding:"2em 1em"
                        
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{color:"#999"}}/>
                                        </InputAdornment>
                                    )
                                }}
                                
                                sx={{marginTop:"40px", width:"100%"}}
                                onKeyDown={searchForVin}
                                fullWidth
                                autoFocus
                            />    
                    }
                </Stack> 
                
                
   
            </Stack>
            <Footer />
        </>
    )
}
