import { createTheme, responsiveFontSizes  } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#004191",
            light: "#1472CE",
            dark: "#004AA6"
        },
        borders: {
            main: "#f2f2f2"
        },

        gray: {
            main: "#989898"
        }
    },
    typography:{
        fontSize:12,
        fontFamily:'Arial', 
        fontWeight:"bold",
        color:"#999"
    },

    transitions: {
        easing: {
            // This is the most common easing curve.
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            // Objects enter the screen at full velocity from off-screen and
            // slowly decelerate to a resting point.
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            // Objects leave the screen at full velocity. They do not decelerate when off-screen.
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            // The sharp curve is used by objects that may return to the screen at any time.
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
        duration: {
            enteringScreen: 200,
            leavingScreen: 200
        }
    },

    // shadows:["none"],

    components: { 

        MuiSvgIcon:{
            styleOverrides: {
                root:{
                    cursor:"pointer",
                    color: '#999 !important'
                    
                }
            }
        },

        MuiPaper: {
            styleOverrides: {
                root:{
                    background:"#222",
                    color:"#999",     
                    padding:"0"        
                }
            }
        },

    
         
        MuiList:{
            defaultProps:{
                disablePadding: true
            }
        },

        MuiAlert:{
            styleOverrides:{
                root:{
                    color:"#fff",
     
                    '& .MuiSvgIcon-root':{
                        color:"#181818 !important",
                        cursor:"default !important"
                    }
                }
            }
        },   

        MuiMenu:{
            styleOverrides:{
                root:{
                    '& .Mui-selected' : {
                        background:"#333 !important",
                        '&:hover':{
                            background:"#333 !important"
                        }
                    },
                    '& .MuiMenuItem-root' : {
                        background:"#222",
                        '&:hover': {
                            background:"#333"
                        },    
                    },
                   
                }
            }
        },

        MuiBackdrop:{
            styleOverrides:{
                root:{
                    backgroundColor:"rgba(0,0,0,0.25)"
                }
            }
        },

        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
                cursor: "pointer"
            },
            styleOverrides:{
                root:{
                    fontWeight:'bold',
                    cursor: "pointer",
                    background:"#333333",
                    '&:hover': {
                        backgroundColor: "#444444",      
                    },
                }
            }
        },

        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: 70,
                    minHeight: 70
                }
            }
        },

        MuiButtonBase:{
            defaultProps:{
                disableRipple: true,
            },
            styleOverrides:{
                root:{
                    cursor:"pointer"
                }
            }
        },
    
        MuiTabs:{
            defaultProps:{           
                TabIndicatorProps:{
                    style: {
                        display:'none'
                    }
                }
            },
        },

        MuiTextField:{
            styleOverrides:{
                root:{
                    '.MuiInputBase-input': { 
                        fontSize: "16p",
                        color:"#fff",
                    },
                    '.MuiInputLabel-root':{
                        color:"#999 !important",
                        fontWeight:"bold"
                        
                    },
                    '& .MuiOutlinedInput-root': {
                        height: '16px',
                        padding: '1.75em 1em',
                        fontSize:'16px',   
                        borderRadius:'6px',
                        border:'0',
                       
                        background:"#282828",
                        
                        '& fieldset': {
                            border:0,
                            fontSize:'2em',      
                        },

                        '&:hover fieldset': {
                            border: '0',
                        },

                        '&:hover': {
                            border:0,
                            background: '#333333',
                        },

                        '&.Mui-focused': {
                            border:0,
                            background:'#333333'
                        },

                        "&.Mui-error":{
                            border:"1px solid #e41B17"
                        }
                    },
                       
                    "& .MuiFormHelperText-root": {
                        color:"#e41B17",
                        margin:"5px 0",
                        fontSize:"0.7rem"
                    },
              
                }    
            }
        },
            
        MuiSelect: {  
            styleOverrides: {
                root:{
                    minHeight:"3.5rem",
                    background:"#282828",
                    color:"#999",
                    fontWeight:"bold",
                    fontSize: "1em",
                    borderRadius:"7px",
                    '& fieldset': {
                        border: '0'
                    },
                    '&:hover': {
                        background: '#333',
                        '&& fieldset': {
                          border: '0'
                        }
                    },
    
                    '&.Mui-focused':{
                        background: '#333',
                        '&& fieldset': {
                            border: '0'
                        }
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#999 !important'
                    }
                }
            }
        },

        MuiDialog:{
            defaultProps:{
                elevation:0,
                disableRestoreFocus: true,
                container: () => document.getElementById("viewWrapper"),
            },
                styleOverrides:{
                root:{
                    zIndex:'998',  
                    position:"absolute",
                    height:"100%",
                    // position:"absolute",
                    // top:"50%",
                    // left:"50%",
                    // transform: "translate(-50%, -50%)",
                    // width:"100%", 
                    // height:"100%"
                }
            }
        },

        MuiDialogContent:{        
            styleOverrides:{
                root:{
                    borderTop:"1px solid #f5f5f5",
                    borderBottom:"1px solid #f5f5f5",
                    padding:"40px !important",
                    position:"relative",
                    overflowX:"hidden"
                }    
            }
        },

        MuiDialogTitle:{
            
            styleOverrides:{
                root:{
                    fontWeight:"bold",
                    // background:"#f9f9f9"
                }    
            }
        },

        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.MuiTableRow-hover:hover': {
                        backgroundColor: '#222',
                    },
                },
            },
        },

        MuiTablePagination:{
            styleOverrides: {
                root:{
                    color:"#999"
                }
            }
        },

        MuiTableCell:{
            styleOverrides:{
                root:{
                    border:0,
                    padding:"0.25em",
                    color:"#fff"
                }
            }
        },
    
    }

});

theme.typography.h1 = {
    fontSize:"3rem",
    fontWeight:"bold",
    [theme.breakpoints.down('lg')]:{
        fontSize:"2.9rem"
    },
    [theme.breakpoints.down('md')]:{
        fontSize:"2.8rem"
    },
    [theme.breakpoints.down('sm')]:{
        fontSize:"2.7rem"
    }
}

theme.typography.h2 = {
    fontSize:"2.6rem",
    fontWeight:"bold",
    [theme.breakpoints.down('lg')]:{
        fontSize:"2.5rem"
    },
    [theme.breakpoints.down('md')]:{
        fontSize:"2.4rem"
    },
    [theme.breakpoints.down('sm')]:{
        fontSize:"2.3rem"
    }
}


theme.typography.h3 = {
    fontSize:"2.2rem",
    fontWeight:"bold",
    [theme.breakpoints.down('lg')]:{
        fontSize:"2.1rem"
    },
    [theme.breakpoints.down('md')]:{
        fontSize:"2rem"
    },
    [theme.breakpoints.down('sm')]:{
        fontSize:"1.9rem"
    }
}

theme.typography.h4 = {
    fontSize:"1.8rem",
    fontWeight:"bold",
    [theme.breakpoints.down('lg')]:{
        fontSize:"1.7rem"
    },
    [theme.breakpoints.down('md')]:{
        fontSize:"1.6rem"
    },
    [theme.breakpoints.down('sm')]:{
        fontSize:"1.5rem"
    }
}

theme.typography.h5 = {
    fontSize:"1.4rem",
    fontWeight:"bold",
    [theme.breakpoints.down('lg')]:{
        fontSize:"1.3rem"
    },
    [theme.breakpoints.down('md')]:{
        fontSize:"1.2rem"
    },
    [theme.breakpoints.down('sm')]:{
        fontSize:"1.1rem"
    }
}

theme.typography.h6 = {
    fontSize:"1rem",
    fontWeight:"bold",
    [theme.breakpoints.down('lg')]:{
        fontSize:"0.9rem"
    },
    [theme.breakpoints.down('md')]:{
        fontSize:"0.8rem"
    },
    [theme.breakpoints.down('sm')]:{
        fontSize:"0.8rem"
    }
}

theme.typography.subtitle1 = {
    fontSize:"0.8rem",
    [theme.breakpoints.up('lg')]:{
        fontSize:"0.9rem"
    }
}

theme.typography.subtitle2 = {
    fontSize:"0.7rem",
    [theme.breakpoints.up('lg')]:{
        fontSize:"0.8rem"
    }
}

theme.typography.body1 = {
    fontSize:"0.7rem",
    [theme.breakpoints.up('lg')]:{
        fontSize:"0.8rem"
    }
}

theme.typography.body2 = {
    fontSize:"0.6em",
    [theme.breakpoints.up('sm')]:{
        fontSize:"0.7rem"
    },
    [theme.breakpoints.up('md')]:{
        fontSize:"0.8rem"
    },
}

theme.typography.button = {
    fontSize:"0.75rem",
    [theme.breakpoints.up('md')]:{
        fontSize:"0.9rem"
    }
}

export default theme;